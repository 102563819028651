import React, { Component } from "react";
import { DetailsDialog, DialogSection } from "../../common/Dialog";
import { Container } from "react-bootstrap";
import ReadOnlyForm from "../../common/ReadOnlyForm";
import { FormatDate } from "../../common/Utils";
import { getStateFromString } from "../StateUtil";

const descriptionStyle = {
  color: "#333",
  display: "block",
  fontSize: "13px",
  lineHeight: "1.42857143",
  margin: "0 0 10px",
  padding: "9.5px",
  overflowWrap: "break-word", // allows breaking long words if needed
  wordBreak: "break-word", // ensures breaking at word boundaries
  maxWidth: "100%", // prevents it from exceeding container width
};

export default class SocialMediaWatchHitDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    this.reload();
  }

  componentDidUpdate(prevProps) {
    if (this.props.url && prevProps.url !== this.props.url) {
      this.reload();
    }
  }

  reload = () => {
    this.setState({ data: null });

    if (this.props.url) {
      this.props.session.backendGet(
        this.props.url,
        (response) => {
          this.setState({ data: response });
        },
        (errorMessage) => {
          this.props.window.showErrorFunc(errorMessage);
        },
      );
    }
  };

  getImages = () => {
    const imageStyle = {
      maxHeight: "300px",
      maxWidth: "300px",
      display: "flex",
      alignItems: "center",
      backgroundColor: "white",
      position: "relative",
      border: "1px solid black",
    };

    const textStyle = {
      paddingLeft: "5px",
    };

    if (this.state.data.images && this.state.data.images.length > 0) {
      let images = [];
      for (const image of this.state.data.images) {
        images.push(
          <tr>
            <td>
              <img src={image.imageUrl} style={imageStyle} alt="" />
            </td>
            <td>
              <div style={textStyle}>
                <pre>
                  External Id: {image.id} <br />
                  Created At: {image.date}
                </pre>
              </div>
            </td>
          </tr>,
        );
      }

      return (
        <DialogSection title="Images">
          <table>
            <tbody>{images}</tbody>
          </table>
        </DialogSection>
      );
    } else {
      return null;
    }
  };

  getDescription = () => {
    return (
      <DialogSection title="Description">
        <div style={descriptionStyle}>{this.state.data.description}</div>
      </DialogSection>
    );
  };

  getHitUrl = () => {
    return (
      <DialogSection title="External hit url">
        <div style={descriptionStyle}>{this.state.data.descriptionUrl}</div>
      </DialogSection>
    );
  };

  getHitInfo = () => {
    let content = [
      {
        label: "External Id",
        value: this.state.data.externalId,
      },
      {
        label: "Discovered",
        value: FormatDate(this.state.data.discovered),
      },
      {
        label: "Updated",
        value: FormatDate(this.state.data.updated),
      },
    ];

    if (this.state.data.tags.length > 0) {
      content.push({
        label: "Categories",
        value: this.state.data.tags,
      });
    }

    content.push({
      label: "State",
      value: getStateFromString(this.state.data.state).name,
    });

    if (this.state.data.sellerName) {
      content.push({
        label: "Name",
        value: this.state.data.sellerName,
      });
    }

    return (
      <DialogSection title="Hit information">
        <ReadOnlyForm wide="false" content={content} />
      </DialogSection>
    );
  };
  render() {
    let parts = [];

    let body = "Loading, please wait...";
    if (this.state.data) {
      parts.push(this.getDescription());
      parts.push(this.getImages());
      parts.push(this.getHitInfo());
      parts.push(this.getHitUrl());
      body = <Container fluid={true}>{parts}</Container>;
    }

    return (
      <DetailsDialog
        show={this.props.show}
        title="Social Media Watch Hit Details"
        body={body}
        onClose={() => this.props.onClose()}
        width="lg"
        height="lg"
      />
    );
  }
}
