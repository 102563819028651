import styled from "styled-components";

export const StateSpan = styled.span`
  .item {
  }
  .item-selected,
  .item:hover {
    border-width: 4px;
    padding-top: 12px;
    text-align: center;
    font-weight: bold;
  }

  .item- {
    border-color: transparent;
  }
  .item-text {
    padding-top: 12px;
    text-align: center;
  }
  .item-text:hover {
    padding-top: 12px;
    text-align: center;
    font-weight: bold;
  }
`;

export const AwaitingApproval = {
  item: "AWAITING_APPROVAL",
  name: "Awaiting Action",
};

export const AwaitingEnforcement = {
  item: "AWAITING_ENFORCEMENT",
  name: "Awaiting Enforcement",
};
const Submitted = {
  item: "SUBMITTED",
  name: "Submitted",
};
const SuccessFul = {
  item: "SUCCESSFUL",
  name: "Successful",
};
const Rejected = {
  item: "REJECTED",
  name: "Rejected",
};
export const NoAction = {
  item: "NO_ACTION",
  name: "No Action",
};
const Innocent = {
  item: "INNOCENT",
  name: "Innocent",
};
const UnEnforceable = {
  item: "UNENFORCEABLE",
  name: "Unenforceable",
};
const UnAuthorized = {
  item: "UNAUTHORISED",
  name: "Unauthorised",
};

export function getStateFromString(stateString) {
  for (const state of StateItems) {
    if (state.item === stateString) {
      return state;
    }
  }
}

export const StateItems = [
  AwaitingApproval,
  AwaitingEnforcement,
  Submitted,
  SuccessFul,
  Rejected,
  NoAction,
  Innocent,
  UnEnforceable,
  UnAuthorized,
];
