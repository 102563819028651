import React, { Component } from "react";
import { Dropdown, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import { FormatDateMonospace, FormatDateTime } from "../../common/Utils";
import {
  DeleteButton,
  MainActionButton,
  NavigationButton,
  TableButton,
} from "../../common/Buttons";
import { TableBackend, TableFilter } from "../../common/Tables";
import SocialMediaWatchHitDialog from "./SocialMediaWatchHitDialog";
import WatchFilter from "../WatchFilter";
import DiscoveredFilter from "../DiscoveredFilter";
import StateFilter from "../StateFilter";
import Colors from "../../common/theme/Colors";
import { UpdateCommentsIcon } from "../../common/theme/Icons";
import WatchCommentsDialog from "../WatchCommentsDialog";
import {
  AwaitingApproval,
  AwaitingEnforcement,
  getStateFromString,
  NoAction,
} from "../StateUtil";
import WatchImages from "../WatchImages";

const PageStyle = styled.div`
  .dropdown {
    vertical-align: top;
    margin-left: 20px;
  }
  .dropdown-menu {
    max-height: 400px;
    overflow: auto;
  }
`;

const CommentColumn = styled.div`
  &.other-reply {
    color: ${Colors.messageLeftText};
  }
`;

export default class SocialMediaWatchHitList extends Component {
  stateMap = new Map();
  commentMap = new Map();
  descriptionMap = new Map();
  watchIdMap = new Map();

  constructor(props) {
    super(props);

    this.state = {
      watchFilterOptions: [],
      selectedWatchDescription: "Any Watch",
      parameters: {
        freetext: "",
        discovered: "30",
        state: AwaitingApproval,
        selectedWatchId: 0,
        reloadFlag: false,
      },
      selectedIds: [],
      hitDialogShow: false,
      hitDialogUrl: null,
      updateProjectDialogIds: [],
      updateProjectDialogSubmit: false,
      updateProjectDialogErrorMessage: null,
      updateStateDialogShow: false,
      updateStateDialogIds: [],
      updateCommentDialogShow: false,
      updateCommentDialogUrl: null,
      updateCommentDialogId: null,
      updateCommentDialogSubmit: false,
      updateCommentDialogErrorMessage: null,
      createOrderDialogShow: false,
      processingIds: false,
      disabledButtons: {},
    };
  }

  componentDidMount() {
    let url =
      this.props.session.getModules().monitoring.links.socialmediawatches;
    url += "?sort=description";
    url += "&page[limit]=0";
    url += "&accountIdFilter=";
    url += "&includeHits=false";
    this.setState({ watchOptionsLoaded: false });
    this.props.session.backendGet(url, (response) => {
      let views = [
        {
          label: "Home",
          url: "/",
        },
        {
          label: "Watches",
          url: "/monitoring/watches",
        },
        {
          label: "Social Media Watch Hits",
          url: null,
        },
      ];

      const socialMediaWatchId = this.props.location.state?.socialMediaWatchId;
      if (socialMediaWatchId) {
        let socialMediaWatch = response.objects.find(
          (watch) => watch.id === socialMediaWatchId,
        );
        if (socialMediaWatch) {
          this.setState({
            selectedWatchDescription: socialMediaWatch.description,
            parameters: Object.assign({}, this.state.parameters, {
              selectedWatchId: socialMediaWatch.id,
            }),
          });
        }
      }
      let watchFilterOptions = this.getWatchFilterOptions(response);
      this.setState({
        watchFilterOptions: watchFilterOptions,
        watchOptionsLoaded: true,
      });

      this.props.window.setBreadcrumbViews(views);
    });
  }

  reload = (ids) => {
    this.setState((prev) => ({
      parameters: Object.assign({}, this.state.parameters, {
        reloadFlag: !this.state.parameters.reloadFlag,
      }),
      processingIds: false,
      disabledButtons: {
        ...prev.disabledButtons,
        ...Object.fromEntries(ids.map((id) => [id, false])),
      },
    }));
  };

  getWatchFilterOptions = (response) => {
    let watches = [];
    watches.push(
      <Dropdown.Item
        key="any-watch-id-filter"
        onClick={(o) => {
          this.setState({
            selectedWatchDescription: "Any Watch",
            parameters: Object.assign({}, this.state.parameters, {
              selectedWatchId: 0,
            }),
          });
        }}
      >
        Any Watch
      </Dropdown.Item>,
    );
    return watches.concat(
      response.objects.map((watch) => {
        return (
          <Dropdown.Item
            key={`watch-key-${watch.id}`}
            onClick={() => {
              this.setState({
                selectedWatchDescription: watch.description,
                parameters: Object.assign({}, this.state.parameters, {
                  selectedWatchId: watch.id,
                }),
              });
            }}
          >
            {watch.description}
          </Dropdown.Item>
        );
      }),
    );
  };

  showHitDialog = (url) => {
    this.setState({
      hitDialogShow: true,
      hitDialogUrl: url,
    });
  };

  hideHitDialog = () => {
    this.setState({
      hitDialogShow: false,
      hitDialogUrl: null,
    });
  };

  onUpdateCommentDialogShow = (url, id) => {
    this.setState({
      updateCommentDialogShow: true,
      updateCommentDialogUrl: url,
      updateCommentDialogId: id,
    });
  };

  onUpdateCommentDialogSave = (newComment) => {
    const callback = () => {
      if (!this.commentMap.get(this.state.updateCommentDialogId)) {
        this.commentMap.set(this.state.updateCommentDialogId, [
          { text: newComment, author: "you", data: new Date().toISOString() },
        ]);
      } else {
        this.commentMap.get(this.state.updateCommentDialogId).push({
          text: newComment,
          author: "you",
          data: new Date().toISOString(),
        });
      }

      this.props.window.showSuccessFunc("Comments updated successfully");
      this.onUpdateCommentDialogCancel();
      this.reload([]);
    };
    const errorFun = (msg) => {
      this.setState({
        updateCommentDialogSubmit: false,
        updateCommentDialogErrorMessage: msg,
      });
    };
    this.setState({ updateCommentDialogSubmit: true });
    this.props.session.backendPut(
      this.state.updateCommentDialogUrl,
      { value: newComment },
      callback,
      errorFun,
    );
  };

  onUpdateCommentDialogCancel = () => {
    this.setState({
      updateCommentDialogShow: false,
      updateCommentDialogUrl: null,
      updateCommentDialogId: null,
      updateCommentDialogSubmit: false,
      updateCommentDialogErrorMessage: null,
    });
  };

  isSelected = (id) => {
    return this.state.selectedIds.includes(id);
  };

  toggleOne = (id) => {
    let selectedIds = this.state.selectedIds;

    if (selectedIds.includes(id)) {
      selectedIds = selectedIds.filter((o) => o !== id);
    } else {
      selectedIds.push(id);
    }

    this.setState({ selectedIds: selectedIds });
  };

  toggleAll = (event) => {
    let selectedIds = [];

    if (event.target.checked) {
      selectedIds = Array.from(this.descriptionMap.keys());
    }

    this.setState({ selectedIds: selectedIds });
  };

  clearAll = (event) => {
    this.setState({ selectedIds: [] });
  };

  getUrl = (sorting, expanding, parameters) => {
    let url =
      this.props.session.getModules().monitoring.links.socialmediawatcheshits;
    url += "?sort=" + sorting;
    url += expanding ? "" : "&page[limit]=15";
    url += "&watchIdFilter=" + parameters.selectedWatchId;
    url += "&discoveredFromFilter=" + encodeURIComponent(parameters.discovered);
    url += "&stateFilter=" + encodeURIComponent(parameters.state.item);
    url += "&freetextFilter=" + encodeURIComponent(parameters.freetext);
    return url;
  };

  onResponse = (response) => {
    this.clearAll();
    this.stateMap.clear();
    this.commentMap.clear();
    this.descriptionMap.clear();
    this.watchIdMap.clear();
    response.objects.forEach((r) => this.commentMap.set(r.id, r.comments));
    response.objects.forEach((r) =>
      this.stateMap.set(r.id, getStateFromString(r.state)),
    );
    response.objects.forEach((r) =>
      this.descriptionMap.set(
        r.id,
        (r.externalId != null ? r.externalId : "") +
          " " +
          (r.description != null ? r.description : ""),
      ),
    );
    response.objects.forEach((r) => this.watchIdMap.set(r.id, r.watchId));
    return response;
  };

  getCategories = (tags) => {
    if (tags && tags.length > 0) {
      return tags.toString();
    } else {
      return null;
    }
  };

  getImages = (images, onClick) => {
    return <WatchImages images={images} onClick={onClick} />;
  };

  onUpdateState = (hitId, newState) => {
    this.state.processingIds = true;
    const callback = () => {
      let oldState = this.stateMap.get(hitId);
      this.stateMap.set(hitId, newState);
      this.props.window.showSuccessFunc(
        "State updated successfully from: " +
          oldState.name +
          " to " +
          newState.name,
      );
      this.reload([hitId]);
    };

    const onError = () => {
      this.props.window.showErrorFunc(
        "Failed to update state for from:" +
          this.stateMap.get(hitId).name +
          " to " +
          newState.name,
      );
      this.reload([hitId]);
    };

    const requestBody = [hitId].map((hitId) => ({
      watchId: this.watchIdMap.get(hitId),
      hitId: hitId,
      state: newState.item,
    }));

    this.props.session.backendPut(
      this.props.session.getModules().monitoring.links.socialwatcheshitstate,
      requestBody,
      callback,
      onError,
    );
  };

  bulkUpdateState = (hitIds, newState, filterIdFunc) => {
    let idsToUpdate = [];
    hitIds.forEach((hitId) => {
      if (filterIdFunc(this.stateMap.get(hitId))) {
        idsToUpdate.push(hitId);
        this.state.processingIds = true;
      }
    });
    const callback = () => {
      idsToUpdate.forEach((id) => {
        this.stateMap.set(id, getStateFromString(newState));
      });
      this.props.window.showSuccessFunc("State updated successfully");
      this.reload(idsToUpdate);
    };

    const requestBody = idsToUpdate.map((hitId) => ({
      watchId: this.watchIdMap.get(hitId),
      hitId: hitId,
      state: newState.item,
    }));

    const onError = () => {
      this.props.window.showErrorFunc(
        "Failed to update some states. You will need to reload",
      );
      this.reload(idsToUpdate);
    };

    this.props.session.backendPut(
      this.props.session.getModules().monitoring.links.socialwatcheshitstate,
      requestBody,
      callback,
      onError,
    );
  };

  getDisabledTooltip = (hasRole, hasSelection) => {
    if (!hasRole) {
      return "Your user account does not have privileges to order opposition pre-investigations, contact your client manager to activate this functionality.";
    } else if (!hasSelection) {
      return (
        "You must first select hits then this button will be enabled depending on what state the the hit is. For example a state in  " +
        AwaitingApproval.name +
        " can not be canceled."
      );
    }
    return "";
  };

  getLimitedText = (textToLimit, maxLength) => {
    if (!textToLimit) {
      return "";
    }

    if (textToLimit.length > maxLength) {
      return textToLimit.slice(0, maxLength) + " ...";
    }

    return textToLimit;
  };

  getDescription = (response) => {
    return <span>{this.getLimitedText(response.description, 150)}</span>;
  };

  getStateItem = (stateString) => {
    return <span>{getStateFromString(stateString).name}</span>;
  };

  getFullCommentText = (row) => {
    return row.commentHeader + ": " + row.text;
  };

  getTruncatedCommentText = (row) => {
    if (this.getFullCommentText(row).length > 90) {
      return this.getFullCommentText(row).substring(0, 91) + "...";
    } else {
      return this.getFullCommentText(row);
    }
  };

  getLastComment = (commentUrl, id) => {
    let comments = this.commentMap.get(id);
    let className = "other-reply";
    let bodyText = [];

    const rows = [];

    comments.forEach((comment) => {
      let dateFormatted = FormatDateTime(comment.date);
      let commentHeader = dateFormatted + " " + comment.author;
      rows.push({
        commentHeader: commentHeader,
        text: comment.text,
      });
    });

    if (rows.length === 0) {
      bodyText.push("");
    } else {
      bodyText.push(this.getTruncatedCommentText(rows[rows.length - 1]));
    }

    const tooltip = (
      <Tooltip>
        <div style={{ textAlign: "left" }}>
          {rows.map((row) => (
            <div>
              {row.commentHeader}
              <br />
              <div style={{ marginLeft: "5px" }}>{row.text}</div>
            </div>
          ))}
        </div>
      </Tooltip>
    );

    const content = (
      <OverlayTrigger overlay={tooltip} placement={"bottom"}>
        <div>
          {bodyText.map((text, index) => (
            <React.Fragment key={index}>
              {text}
              {index !== bodyText.length - 1 && <br />}
            </React.Fragment>
          ))}
        </div>
      </OverlayTrigger>
    );

    return (
      <CommentColumn
        className={className}
        onClick={() => this.onUpdateCommentDialogShow(commentUrl, id)}
      >
        <div>{rows.length >= 2 ? "..." : ""} </div>
        {content}
        <UpdateCommentsIcon
          onClick={() => this.onUpdateCommentDialogShow(commentUrl, id)}
          title="Update comments for this hit"
        />
      </CommentColumn>
    );
  };

  isPossibleToIgnore = (state) => {
    switch (state) {
      case AwaitingApproval:
      case AwaitingEnforcement:
        return true;
      default:
        return false;
    }
  };

  isPossibleToCancel = (state) => {
    switch (state) {
      case NoAction:
      case AwaitingEnforcement:
        return true;
      default:
        return false;
    }
  };

  isPossibleToTakeDown = (state) => {
    return state === AwaitingApproval;
  };

  getIgnoreButton = (r) => {
    const isDisabled =
      this.state.disabledButtons && this.state.disabledButtons[r.id];
    return (
      <TableButton
        style={{ marginBottom: "10px" }}
        onClick={() => {
          this.setState((prevState) => ({
            disabledButtons: {
              ...prevState.disabledButtons,
              [r.id]: true,
            },
          }));
          this.onUpdateState(r.id, NoAction);
        }}
        icon="ban"
        prefix={"fas"}
        text="Ignore"
        disabled={isDisabled}
        className={
          this.isPossibleToIgnore(this.stateMap.get(r.id))
            ? isDisabled
              ? "disabled"
              : "secondary"
            : "hidden"
        }
        tooltip={(() => {
          const state = this.stateMap.get(r.id);
          switch (state) {
            case AwaitingApproval:
              return "Ignore this hit";
            case AwaitingEnforcement:
              return "Move this hit to " + NoAction.name;
            default:
              return "";
          }
        })()}
      />
    );
  };
  isDisabled = (id) => {
    return this.state.disabledButtons && this.state.disabledButtons[id];
  };
  getTakeDownButton = (r) => {
    return (
      <TableButton
        style={{ marginBottom: "10px" }}
        onClick={() => {
          this.setState((prevState) => ({
            disabledButtons: {
              ...prevState.disabledButtons,
              [r.id]: true,
            },
          }));
          this.onUpdateState(r.id, AwaitingEnforcement);
        }}
        icon="bullhorn"
        prefix={"fas"}
        text="Take Down"
        disabled={this.isDisabled(r.id)}
        className={
          this.isPossibleToTakeDown(this.stateMap.get(r.id))
            ? this.isDisabled(r.id)
              ? "disabled"
              : "ok"
            : "hidden"
        }
        tooltip={
          this.isPossibleToTakeDown(this.stateMap.get(r.id))
            ? "Order Take Down"
            : "Not possible Take Down when state"
        }
      />
    );
  };

  getCancelButton = (r) => {
    const isDisabled =
      this.state.disabledButtons && this.state.disabledButtons[r.id];
    return (
      <TableButton
        style={{ marginBottom: "10px" }}
        onClick={() => {
          this.setState((prevState) => ({
            disabledButtons: {
              ...prevState.disabledButtons,
              [r.id]: true,
            },
          }));
          this.onUpdateState(r.id, AwaitingApproval);
        }}
        icon="undo"
        prefix={"fas"}
        text="Cancel"
        disabled={isDisabled}
        className={
          this.isPossibleToCancel(this.stateMap.get(r.id))
            ? isDisabled
              ? "disabled"
              : "remove"
            : "hidden"
        }
        tooltip={
          this.isPossibleToCancel(this.stateMap.get(r.id))
            ? "Move hit back to " + AwaitingApproval.name
            : "You can not Move this hit back to :" + AwaitingApproval.name
        }
      />
    );
  };

  getHitDialogButton = (r) => {
    return (
      <TableButton
        style={{ marginBottom: "10px" }}
        onClick={() => this.showHitDialog(r.links.self)}
        icon="eye"
        prefix={"fas"}
        text="Details"
        className={"secondary"}
      />
    );
  };

  getExternalHitButton = (r) => {
    return (
      <TableButton
        style={{ marginBottom: "10px" }}
        onClick={() =>
          window.open(r.descriptionUrl, "_blank", "noopener,noreferrer")
        }
        icon="up-right-from-square"
        prefix={"fas"}
        text="Open External"
        className="secondary"
        tooltip={
          "Open the external URL where this hit refers to. I.e, this will take you to: " +
          r.descriptionUrl
        }
      />
    );
  };

  getButtons = (r) => {
    let buttons = [];
    buttons.push(this.getTakeDownButton(r));
    buttons.push(this.getIgnoreButton(r));
    buttons.push(this.getCancelButton(r));
    buttons.push(this.getExternalHitButton(r));
    buttons.push(this.getHitDialogButton(r));
    return buttons;
  };
  render() {
    let actions = [];

    actions.push(
      <MainActionButton
        key="bulk-take-down-state"
        onClick={() => {
          this.setState((prevState) => ({
            disabledButtons: {
              ...prevState.disabledButtons,
              ...Object.fromEntries(
                prevState.selectedIds.map((id) => [
                  id,
                  this.isPossibleToTakeDown(this.stateMap.get(id)),
                ]),
              ),
            },
          }));
          this.bulkUpdateState(
            this.state.selectedIds,
            AwaitingEnforcement,
            this.isPossibleToTakeDown,
          );
        }}
        text="Take Down"
        icon="bullhorn"
        prefix="fas"
        tooltip="Order take down of these hits."
        disabled={
          this.state.selectedIds == null ||
          this.state.selectedIds.length === 0 ||
          this.state.processingIds ||
          this.state.selectedIds.every(
            (id) => !this.isPossibleToTakeDown(this.stateMap.get(id)),
          )
        }
        disabledTooltip={this.getDisabledTooltip(
          true,
          this.state.selectedIds != null && this.state.selectedIds.length > 0,
        )}
      />,
      <NavigationButton
        key="bulk-ignore-state"
        onClick={() => {
          this.setState((prevState) => ({
            disabledButtons: {
              ...prevState.disabledButtons,
              ...Object.fromEntries(
                this.state.selectedIds.map((id) => [
                  id,
                  this.isPossibleToIgnore(this.stateMap.get(id)),
                ]),
              ),
            },
          }));
          this.bulkUpdateState(
            this.state.selectedIds,
            NoAction,
            this.isPossibleToIgnore,
          );
        }}
        text="Ignore"
        icon="ban"
        prefix="fas"
        tooltip={"Move these hits to " + NoAction.name}
        disabled={
          this.state.selectedIds == null ||
          this.state.selectedIds.length === 0 ||
          this.state.processingIds ||
          this.state.selectedIds.every(
            (id) => !this.isPossibleToIgnore(this.stateMap.get(id)),
          )
        }
        disabledTooltip={this.getDisabledTooltip(
          true,
          this.state.selectedIds != null && this.state.selectedIds.length > 0,
        )}
      />,
      <DeleteButton
        key="bulk-cancel"
        onClick={() => {
          this.setState((prevState) => ({
            disabledButtons: {
              ...prevState.disabledButtons,
              ...Object.fromEntries(
                this.state.selectedIds.map((id) => [
                  id,
                  this.isPossibleToCancel(this.stateMap.get(id)),
                ]),
              ),
            },
          }));
          this.bulkUpdateState(
            this.state.selectedIds,
            AwaitingApproval,
            this.isPossibleToCancel,
          );
        }}
        text="Cancel"
        icon="undo"
        prefix="fas"
        tooltip={"Move these hits back to " + AwaitingApproval.name}
        disabled={
          this.state.selectedIds == null ||
          this.state.selectedIds.length === 0 ||
          this.state.processingIds ||
          this.state.selectedIds.every(
            (id) => !this.isPossibleToCancel(this.stateMap.get(id)),
          )
        }
        disabledTooltip={this.getDisabledTooltip(
          true,
          this.state.selectedIds != null && this.state.selectedIds.length > 0,
        )}
      />,
    );

    const filters = [
      <WatchFilter
        key="watch-filter"
        title={this.state.selectedWatchDescription}
        options={this.state.watchFilterOptions}
      />,
      <StateFilter
        key="state-filter"
        value={this.state.parameters.state}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, { state: o }),
          })
        }
      />,
      <DiscoveredFilter
        key="discovered-filter"
        value={this.state.parameters.discovered}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              discovered: o,
            }),
          })
        }
      />,
      <TableFilter
        key="table-filter"
        value={this.state.parameters.freetext}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              freetext: o,
            }),
          })
        }
      />,
    ];

    let columns = [];

    columns.push({
      label: <Form.Check onChange={this.toggleAll} />,
      style: { width: "50px" },
      contentFunction: (r) => (
        <Form.Check
          checked={this.isSelected(r.id)}
          onChange={() => this.toggleOne(r.id)}
        />
      ),
    });

    columns.push(
      {
        label: "Number",
        name: "externalId",
        style: { width: "100px" },
        sortable: true,
        contentFunction: (r) => (
          <span className={"monospace"}>{r.externalId}</span>
        ),
      },
      {
        label: "Description",
        name: "description",
        style: { width: "200px" },
        sortable: false,
        contentFunction: (r) => this.getDescription(r),
      },
      {
        label: "Image",
        name: "images",
        style: { width: "110px" },
        contentFunction: (r) =>
          this.getImages(r.images, () => this.showHitDialog(r.links.self)),
      },
      {
        label: "Platform",
        name: "platform",
        style: { width: "120px" },
        sortable: true,
      },
      {
        label: "Categories",
        name: "tags",
        style: { width: "120px" },
        sortable: false,
        contentFunction: (r) => <span>{this.getCategories(r.tags)}</span>,
      },
      {
        label: "Discovered",
        name: "discovered",
        style: { width: "120px" },
        sortable: true,
        contentFunction: (r) => FormatDateMonospace(r.discovered),
      },
      {
        label: "Updated",
        name: "updated",
        style: { width: "100px" },
        sortable: true,
        contentFunction: (r) => FormatDateMonospace(r.updated),
      },
      {
        label: "State",
        name: "state",
        style: { width: "140px" },
        contentFunction: (r) => this.getStateItem(r.state),
      },
      {
        label: "Last Comment",
        name: "comments",
        style: { width: "200px" },
        contentFunction: (r) => this.getLastComment(r.links.comment, r.id),
        sortable: false,
      },
      {
        contentFunction: (r) => (
          <div style={{ textAlign: "right" }}>{this.getButtons(r)}</div>
        ),
      },
    );

    return (
      <PageStyle>
        {this.state.watchOptionsLoaded && (
          <TableBackend
            id="watch.socialmediawatch.hits"
            session={this.props.session}
            window={this.props.window}
            actions={actions}
            filters={filters}
            columns={columns}
            sorting="-discovered"
            parameters={this.state.parameters}
            urlFunction={this.getUrl}
            responseFunction={this.onResponse}
          />
        )}
        <SocialMediaWatchHitDialog
          session={this.props.session}
          window={this.props.window}
          show={this.state.hitDialogShow}
          url={this.state.hitDialogUrl}
          onClose={this.hideHitDialog}
        />
        <WatchCommentsDialog
          session={this.props.session}
          window={this.props.window}
          show={this.state.updateCommentDialogShow}
          comments={this.commentMap.get(this.state.updateCommentDialogId)}
          onSave={this.onUpdateCommentDialogSave}
          onCancel={this.onUpdateCommentDialogCancel}
          submit={this.state.updateCommentDialogSubmit}
          errorMessage={this.state.updateCommentDialogErrorMessage}
        />
      </PageStyle>
    );
  }
}
