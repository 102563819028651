import React, { Component, useEffect, useRef, useState } from "react";
import { Loader } from "../../common/theme/Theme";
import { NavTab } from "react-router-tabs";
import Icon from "../../common/theme/Icons";
import { Redirect, Route, Switch } from "react-router-dom";
import TagHeader from "./TagHeader";
import TagObjectsTab from "./TagObjectsTab";
import { PageViewTracker } from "../../common/Tracking";
import styled from "styled-components";
import { PageTabsNew } from "../../common/PageTabs";
import Colors from "../../common/theme/Colors";

const ScrollContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const ScrollableTabsWrapper = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  flex-grow: 1;
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
  background: ${Colors.white};
`;

const ScrollArrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: ${({ show }) =>
    show ? "flex" : "none"}; /* Only show when needed */
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  border-radius: 50%;
  z-index: 10;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const LeftArrow = styled(ScrollArrow)`
  left: 5px;
`;

const RightArrow = styled(ScrollArrow)`
  right: 5px;
`;

const TagTabs = styled(PageTabsNew)`
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
`;

class TagPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      tag: null,
      baseUrl: null,
      activeTab: 1,
      errorMessage: null,
      submit: null,
      tabPosition: 0,
    };
  }

  componentDidMount() {
    this.reload();
  }

  reload = () => {
    let url =
      this.props.session.getModules().useradmin.links.tags +
      "/" +
      this.props.match.params.tagId;
    this.props.session.backendGet(url, (response) => {
      this.setState({
        tag: response,
        loaded: true,
        baseUrl: `/useradmin/tags/${response.id}`,
      });
      let views = [
        {
          label: "Home",
          url: "/",
        },
        {
          label: "Tags",
          url: "/useradmin/tags",
        },
        {
          label: response.name,
          url: null,
        },
      ];

      this.props.window.setBreadcrumbViews(views);
      this.props.window.setPageTip(null);
    });
  };

  handleTabSelect = (event, onClick) => {
    const clickedTab = event.target.getAttribute("data-tab");
    this.setState({ activeTab: clickedTab });
    onClick();
  };

  getTagIpRightColumns = () => {
    return [
      {
        label: "Abion Case Number",
        name: "caseNumber",
        sortable: true,
        filterable: true,
      },
      {
        label: "Type",
        name: "type",
      },
      {
        label: "Description",
        name: "description",
        sortable: true,
        filterable: true,
      },
    ];
  };

  getTagDomainColumns = () => {
    return [
      {
        label: "Domain",
        name: "domainName",
        sortable: true,
        filterable: true,
      },
    ];
  };

  getTagCertColumns = () => {
    return [
      {
        label: "Id",
        name: "id",
        sortable: false,
        filterable: false,
      },
      {
        label: "Description",
        name: "description",
        sortable: true,
        filterable: true,
      },
      {
        label: "Type",
        name: "type",
        sortable: true,
        filterable: true,
      },
    ];
  };

  getTagZoneColumns = () => {
    return [
      {
        label: "Zone",
        name: "zoneName",
        sortable: true,
        filterable: true,
      },
    ];
  };

  getTagServiceColumns = () => {
    return [
      {
        label: "Id",
        name: "id",
        sortable: false,
        filterable: false,
      },
      {
        label: "Description",
        name: "description",
        sortable: true,
        filterable: true,
      },
      {
        label: "Type",
        name: "type",
        sortable: true,
        filterable: true,
      },
    ];
  };

  getTagTrademarkWatchColumns = () => {
    return [
      {
        label: "Id",
        name: "id",
        sortable: false,
        filterable: false,
      },
      {
        label: "Description",
        name: "description",
        sortable: true,
        filterable: true,
      },
    ];
  };

  getTagDomainWatchColumns = () => {
    return [
      {
        label: "Id",
        name: "id",
        sortable: false,
        filterable: false,
      },
      {
        label: "Description",
        name: "description",
        sortable: true,
        filterable: true,
      },
    ];
  };

  getTagWebContentWatchColumns = () => {
    return [
      {
        label: "Id",
        name: "id",
        sortable: false,
        filterable: false,
      },
      {
        label: "Description",
        name: "description",
        sortable: true,
        filterable: true,
      },
    ];
  };

  getTagDomainMonitorColumns = () => {
    return [
      {
        label: "Id",
        name: "id",
        sortable: false,
        filterable: false,
      },
      {
        label: "Description",
        name: "description",
        sortable: true,
        filterable: true,
      },
    ];
  };

  getTagSocialMediaWatchColumns = () => {
    return [
      {
        label: "Id",
        name: "id",
        sortable: false,
        filterable: false,
      },
      {
        label: "Description",
        name: "description",
        sortable: true,
        filterable: true,
      },
    ];
  };

  render() {
    const ipRights = () => {
      return (
        <TagObjectsTab
          session={this.props.session}
          window={this.props.window}
          tag={this.state.tag}
          columns={this.getTagIpRightColumns()}
          sorting={"caseNumber"}
          getObjectsUrl={this.state.tag?.links.ipRights}
          addObjectsUrl={this.state.tag?.links.addIpRights}
          removeObjectsUrl={this.state.tag?.links.removeIpRights}
          relationType={"IP Rights"}
          idFieldName={"Abion case numbers"}
        />
      );
    };
    const domains = () => {
      return (
        <TagObjectsTab
          session={this.props.session}
          window={this.props.window}
          tag={this.state.tag}
          columns={this.getTagDomainColumns()}
          sorting={"name"}
          getObjectsUrl={this.state.tag?.links.domains}
          addObjectsUrl={this.state.tag?.links.addDomains}
          removeObjectsUrl={this.state.tag?.links.removeDomains}
          relationType={"Domains"}
          idFieldName={"domain names"}
        />
      );
    };
    const zones = () => {
      return (
        <TagObjectsTab
          session={this.props.session}
          window={this.props.window}
          tag={this.state.tag}
          columns={this.getTagZoneColumns()}
          sorting={"zonename"}
          getObjectsUrl={this.state.tag?.links.zones}
          addObjectsUrl={this.state.tag?.links.addZones}
          removeObjectsUrl={this.state.tag?.links.removeZones}
          relationType={"Zones"}
          idFieldName={"zone names"}
        />
      );
    };
    const certs = () => {
      return (
        <TagObjectsTab
          session={this.props.session}
          window={this.props.window}
          tag={this.state.tag}
          columns={this.getTagCertColumns()}
          sorting={"description"}
          getObjectsUrl={this.state.tag?.links.certs}
          addObjectsUrl={this.state.tag?.links.addCerts}
          removeObjectsUrl={this.state.tag?.links.removeCerts}
          relationType={"Certificates"}
          idFieldName={"certificate ids"}
        />
      );
    };
    const services = () => {
      return (
        <TagObjectsTab
          session={this.props.session}
          window={this.props.window}
          tag={this.state.tag}
          columns={this.getTagServiceColumns()}
          sorting={"description"}
          getObjectsUrl={this.state.tag?.links.services}
          addObjectsUrl={this.state.tag?.links.addServices}
          removeObjectsUrl={this.state.tag?.links.removeServices}
          relationType={"Services"}
          idFieldName={"service ids"}
        />
      );
    };

    const trademarkWatches = () => {
      return (
        <TagObjectsTab
          session={this.props.session}
          window={this.props.window}
          tag={this.state.tag}
          columns={this.getTagTrademarkWatchColumns()}
          sorting={"description"}
          getObjectsUrl={this.state.tag?.links.trademarkWatches}
          addObjectsUrl={this.state.tag?.links.addTrademarkWatches}
          removeObjectsUrl={this.state.tag?.links.removeTrademarkWatches}
          relationType={"Trademark Watches"}
          idFieldName={"trademark watch ids"}
        />
      );
    };

    const domainWatches = () => {
      return (
        <TagObjectsTab
          session={this.props.session}
          window={this.props.window}
          tag={this.state.tag}
          columns={this.getTagDomainWatchColumns()}
          sorting={"description"}
          getObjectsUrl={this.state.tag?.links.domainWatches}
          addObjectsUrl={this.state.tag?.links.addDomainWatches}
          removeObjectsUrl={this.state.tag?.links.removeDomainWatches}
          relationType={"Domain Watches"}
          idFieldName={"domain watch ids"}
        />
      );
    };

    const webContentWatches = () => {
      return (
        <TagObjectsTab
          session={this.props.session}
          window={this.props.window}
          tag={this.state.tag}
          columns={this.getTagWebContentWatchColumns()}
          sorting={"description"}
          getObjectsUrl={this.state.tag?.links.webContentWatches}
          addObjectsUrl={this.state.tag?.links.addWebContentWatches}
          removeObjectsUrl={this.state.tag?.links.removeWebContentWatches}
          relationType={"Web Content Watches"}
          idFieldName={"web content watch ids"}
        />
      );
    };

    const domainMonitors = () => {
      return (
        <TagObjectsTab
          session={this.props.session}
          window={this.props.window}
          tag={this.state.tag}
          columns={this.getTagDomainMonitorColumns()}
          sorting={"description"}
          getObjectsUrl={this.state.tag?.links.domainMonitors}
          addObjectsUrl={this.state.tag?.links.addDomainMonitors}
          removeObjectsUrl={this.state.tag?.links.removeDomainMonitors}
          relationType={"Domain Monitors"}
          idFieldName={"domain monitor ids"}
        />
      );
    };

    const socialMediaWatches = () => {
      return (
        <TagObjectsTab
          session={this.props.session}
          window={this.props.window}
          tag={this.state.tag}
          columns={this.getTagSocialMediaWatchColumns()}
          sorting={"description"}
          getObjectsUrl={this.state.tag?.links.socialMediaWatches}
          addObjectsUrl={this.state.tag?.links.addSocialMediaWatches}
          removeObjectsUrl={this.state.tag?.links.removeSocialMediaWatches}
          relationType={"Social Media Watches"}
          idFieldName={"social media watch ids"}
        />
      );
    };

    const Tabs = (props) => {
      let tabs = [];
      let tabIndex = 1;
      tabs.push(
        <NavTab
          onClick={(event) => this.handleTabSelect(event, props.onClick)}
          key={"tag-ipRights"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/ipRights`}
        >
          <Icon name="gavel" /> IP Rights
        </NavTab>,
      );
      tabs.push(
        <NavTab
          onClick={(event) => this.handleTabSelect(event, props.onClick)}
          key={"tag-domains"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/domains`}
        >
          <Icon name="globe" /> Domains
        </NavTab>,
      );
      tabs.push(
        <NavTab
          onClick={(event) => this.handleTabSelect(event, props.onClick)}
          key={"tag-zones"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/zones`}
        >
          <Icon name="globe-africa" /> Zones
        </NavTab>,
      );
      tabs.push(
        <NavTab
          onClick={(event) => this.handleTabSelect(event, props.onClick)}
          key={"tag-certs"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/certs`}
        >
          <Icon name="lock" /> Certificates
        </NavTab>,
      );
      tabs.push(
        <NavTab
          onClick={(event) => this.handleTabSelect(event, props.onClick)}
          key={"tag-services"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/services`}
        >
          <Icon name="briefcase" /> Services
        </NavTab>,
      );
      tabs.push(
        <NavTab
          onClick={(event) => this.handleTabSelect(event, props.onClick)}
          key={"tag-trademarkwatches"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/trademarkWatches`}
        >
          <Icon name="briefcase" /> Trademark Watches
        </NavTab>,
      );
      tabs.push(
        <NavTab
          onClick={(event) => this.handleTabSelect(event, props.onClick)}
          key={"tag-domainwatches"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/domainWatches`}
        >
          <Icon name="briefcase" /> Domain Watches
        </NavTab>,
      );
      tabs.push(
        <NavTab
          onClick={(event) => this.handleTabSelect(event, props.onClick)}
          key={"tag-webcontentwatches"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/webContentWatches`}
        >
          <Icon name="briefcase" /> Web Content Watches
        </NavTab>,
      );
      tabs.push(
        <NavTab
          onClick={(event) => this.handleTabSelect(event, props.onClick)}
          key={"tag-domainmonitors"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/domainMonitors`}
        >
          <Icon name="briefcase" /> Domain Monitors
        </NavTab>,
      );
      tabs.push(
        <NavTab
          onClick={(event) => this.handleTabSelect(event, props.onClick)}
          key={"tag-socialmediawatches"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/socialMediaWatches`}
        >
          <Icon name="briefcase" /> Social Media Watches
        </NavTab>,
      );
      return tabs;
    };

    const PageTabsContainer = () => {
      const scrollRef = useRef({ scrollLeft: this.state.tabPosition });
      const [scrollPosition, setScrollPosition] = useState(() => {
        return scrollRef.current ? scrollRef.current.scrollLeft : 0;
      });
      const [showLeftArrow, setShowLeftArrow] = useState(false);
      const [showRightArrow, setShowRightArrow] = useState(false);
      const scrollInterval = useRef(null);

      const handleTabClick = () => {
        if (scrollRef.current) {
          setScrollPosition(scrollRef.current.scrollLeft);
          this.setState({ tabPosition: scrollRef.current.scrollLeft });
        }
      };

      useEffect(() => {
        if (
          scrollRef.current &&
          scrollRef.current.scrollLeft !== scrollPosition
        ) {
          scrollRef.current.scrollLeft = scrollPosition;
        }
      }, [scrollPosition]);

      useEffect(() => {
        const checkOverflow = () => {
          if (scrollRef.current) {
            const { scrollWidth, clientWidth, scrollLeft } = scrollRef.current;
            setShowLeftArrow(scrollLeft > 0);
            setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
          }
        };

        checkOverflow();
        scrollRef.current.addEventListener("scroll", checkOverflow);
        window.addEventListener("resize", checkOverflow);

        return () => {
          scrollRef.current?.removeEventListener("scroll", checkOverflow);
          window.removeEventListener("resize", checkOverflow);
        };
      }, []);

      const startScrolling = (direction) => {
        if (scrollRef.current) {
          stopScrolling();
          scrollInterval.current = setInterval(() => {
            scrollRef.current.scrollBy({
              left: direction === "left" ? -5 : 5,
              behavior: "auto",
            });
          }, 20);
        }
      };

      const stopScrolling = () => {
        clearInterval(scrollInterval.current);
      };

      return (
        <ScrollContainer>
          <LeftArrow
            show={showLeftArrow}
            onMouseEnter={() => startScrolling("left")}
            onMouseLeave={stopScrolling}
          >
            <Icon name="arrow-left" />
          </LeftArrow>
          <ScrollableTabsWrapper ref={scrollRef}>
            <TagTabs className={"nav nav-tabs"}>
              <Tabs
                tag={this.state.tag}
                baseUrl={this.state.baseUrl}
                onClick={handleTabClick}
              />
            </TagTabs>
          </ScrollableTabsWrapper>
          <RightArrow
            show={showRightArrow}
            onMouseEnter={() => startScrolling("right")}
            onMouseLeave={stopScrolling}
          >
            <Icon name="arrow-right" />
          </RightArrow>
        </ScrollContainer>
      );
    };

    return (
      <Loader loaded={this.state.loaded}>
        <PageViewTracker session={this.props.session} />
        <TagHeader
          session={this.props.session}
          window={this.props.window}
          tag={this.state.tag}
        />
        <PageTabsContainer>{Tabs}</PageTabsContainer>
        <Switch>
          <Route
            exact
            path={`${this.state.baseUrl}`}
            key={"route-information-redirect-1"}
            render={() => (
              <Redirect replace to={`${this.state.baseUrl}/ipRights`} />
            )}
          />
          <Route
            path={`${this.state.baseUrl}/ipRights`}
            component={ipRights}
            key={"route-information"}
          />
          <Route
            path={`${this.state.baseUrl}/domains`}
            component={domains}
            key={"route-information"}
          />
          <Route
            path={`${this.state.baseUrl}/zones`}
            component={zones}
            key={"route-information"}
          />
          <Route
            path={`${this.state.baseUrl}/certs`}
            component={certs}
            key={"route-information"}
          />
          <Route
            path={`${this.state.baseUrl}/services`}
            component={services}
            key={"route-information"}
          />
          <Route
            path={`${this.state.baseUrl}/trademarkWatches`}
            component={trademarkWatches}
            key={"route-information"}
          />
          <Route
            path={`${this.state.baseUrl}/domainWatches`}
            component={domainWatches}
            key={"route-information"}
          />
          <Route
            path={`${this.state.baseUrl}/webContentWatches`}
            component={webContentWatches}
            key={"route-information"}
          />
          <Route
            path={`${this.state.baseUrl}/domainMonitors`}
            component={domainMonitors}
            key={"route-information"}
          />
          <Route
            path={`${this.state.baseUrl}/socialMediaWatches`}
            component={socialMediaWatches}
            key={"route-information"}
          />
        </Switch>
      </Loader>
    );
  }
}

export default TagPage;
