import styled from "styled-components";
import React, { Component } from "react";
import Colors from "../common/theme/Colors";

const ImagesStyle = styled.div`
  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 80px;
    width: 80px;
    border: 1px solid ${Colors.tertiary};
    margin-bottom: 0;
    transition: 0.3s ease-in-out;
  }

  img.default-img {
    border: 2px solid ${Colors.white};
    background-color: ${Colors.white};
  }

  .images {
    justify-content: center;
    position: relative;
    width: max-content;
    max-height: 100px;
    max-width: 100px;
    left: 0;
    right: 0;
  }

  .images img {
    cursor: pointer;
    transition: all 0.3s ease-out;
    left: 0;
  }

  .images img:nth-child(1) {
    z-index: 1;
    position: relative;
  }

  .images img:nth-child(2) {
    z-index: 2;
    transform: translate(4%, -4%); /* Slightly shifts the bottom image */
  }

  .images img:nth-child(3) {
    z-index: 3;
    transform: translate(8%, -8%);
  }

  .images:hover img:nth-child(1) {
    transform: scale(1.02);
  }

  .images:hover img:nth-child(2) {
    transform: scale(1.02) translate(6%, -6%);
  }

  .images:hover img:nth-child(3) {
    transform: scale(1.02) translate(12%, -12%);
  }
`;

export default class WatchImages extends Component {
  getImage(imageUrl, margin) {
    return <img style={{ marginTop: margin + "px" }} src={imageUrl} alt={""} />;
  }

  getImages(images) {
    if (images && images.length > 0) {
      let imageElements = [];
      for (let i = 0; i < images.length; i++) {
        if (i === 3) {
          break;
        }
        let margin = Math.min(images.length, 3) * 2;
        imageElements.push(this.getImage(images[i].imageUrl, margin));
      }

      return imageElements;
    } else {
      return null;
    }
  }

  render() {
    if (this.props.singleContact) {
      return <ImagesStyle>{this.getImage(this.props.photoBody)}</ImagesStyle>;
    } else {
      return (
        <ImagesStyle>
          <div className="images" onClick={this.props.onClick}>
            {this.getImages(this.props.images)}
          </div>
        </ImagesStyle>
      );
    }
  }
}
