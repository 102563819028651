import React, { Component } from "react";
import styled from "styled-components";
import Colors from "../common/theme/Colors";
import { LinkContainer } from "react-router-bootstrap";
import { TableButton } from "../common/Buttons";
import { FormatDateMonospace, FormatIntegerMonospace } from "../common/Utils";

const DivStyle = styled.div`
  margin: 0 0;

  table {
    width: 100%;
    cursor: default;
    border: 1px solid ${Colors.border};
    background-color: ${Colors.white};
  }

  tr {
    border-top: 1px solid ${Colors.border};
  }

  th {
    font-weight: 600;
    font-size: 16px;
    padding: 10px 15px 10px 15px;
    vertical-align: top;
  }

  th:first-child {
    padding-left: 35px;
  }

  th:not(:first-child) {
    padding-right: 35px;
    text-align: right;
  }

  td {
    padding: 5px;
  }

  td:first-child {
    padding-left: 35px;
  }

  td:not(:first-child) {
    padding-right: 35px;
    text-align: right;
  }

  .footer-text {
    margin-top: 15px;
    margin-left: 10px;
    font-size: 16px;
  }
`;

export default class WatchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      watches: {},
      loading: true,
    };
  }

  componentDidMount() {
    let url = this.props.session.getModules().monitoring.links.watches;

    this.props.session.backendGet(url, (response) => {
      let views = [
        {
          label: "Home",
          url: "/",
        },
        {
          label: "Watches",
          url: null,
        },
      ];

      this.setState({
        watches: response,
        loading: false,
      });

      this.props.window.setBreadcrumbViews(views);
      this.props.window.setPageTip(null);
    });
  }
  getItems = () => {
    let hasRoleWatch = this.props.session.hasRole("WATCH");
    return [
      this.getTrademarkWatchItem(hasRoleWatch),
      this.getDomainWatchItem(hasRoleWatch),
      this.getWebContentWatchItem(hasRoleWatch),
      this.getDomainMonitorItem(hasRoleWatch),
      this.getSocialMediaWatchItem(hasRoleWatch),
    ];
  };

  getTrademarkWatchItem = (hasRoleWatch) => {
    let exists = this.state.watches.trademarkWatch != null;
    let hitsTotal = exists ? this.state.watches.trademarkWatch.hitsTotal : 0;
    let hitsThirtyDays = exists
      ? this.state.watches.trademarkWatch.hitsThirtyDays
      : 0;
    let hitsSevenDays = exists
      ? this.state.watches.trademarkWatch.hitsSevenDays
      : 0;
    let lastDiscovered = exists
      ? this.state.watches.trademarkWatch.lastDiscovered
      : null;

    return {
      description: "Trademark Watches",
      watchPath: hasRoleWatch ? "/monitoring/watches/trademarkwatches" : null,
      hitsPath: hasRoleWatch ? "/monitoring/watches/trademarkwatchhits" : null,
      hitsTotal,
      hitsThirtyDays,
      hitsSevenDays,
      lastDiscovered,
    };
  };

  getDomainWatchItem = (hasRoleWatch) => {
    let exists = this.state.watches.domainWatch != null;
    let hitsTotal = exists ? this.state.watches.domainWatch.hitsTotal : 0;
    let hitsThirtyDays = exists
      ? this.state.watches.domainWatch.hitsThirtyDays
      : 0;
    let hitsSevenDays = exists
      ? this.state.watches.domainWatch.hitsSevenDays
      : 0;
    let lastDiscovered = exists
      ? this.state.watches.domainWatch.lastDiscovered
      : null;

    return {
      description: "Domain Watches",
      watchPath: hasRoleWatch ? "/monitoring/watches/domainwatches" : null,
      hitsPath: hasRoleWatch ? "/monitoring/watches/domainwatchhits" : null,
      hitsTotal,
      hitsThirtyDays,
      hitsSevenDays,
      lastDiscovered,
    };
  };

  getWebContentWatchItem = (hasRoleWatch) => {
    let exists = this.state.watches.webContentWatch != null;
    let hitsTotal = exists ? this.state.watches.webContentWatch.hitsTotal : 0;
    let hitsThirtyDays = exists
      ? this.state.watches.webContentWatch.hitsThirtyDays
      : 0;
    let hitsSevenDays = exists
      ? this.state.watches.webContentWatch.hitsSevenDays
      : 0;
    let lastDiscovered = exists
      ? this.state.watches.webContentWatch.lastDiscovered
      : null;
    return {
      description: "Web Content Watches",
      watchPath: hasRoleWatch ? "/monitoring/watches/webcontentwatches" : null,
      hitsPath: hasRoleWatch ? "/monitoring/watches/webcontentwatchhits" : null,
      hitsTotal,
      hitsThirtyDays,
      hitsSevenDays,
      lastDiscovered,
    };
  };

  getDomainMonitorItem = (hasRoleWatch) => {
    let exists = this.state.watches.domainMonitoring != null;
    let hitsTotal = exists ? this.state.watches.domainMonitoring.hitsTotal : 0;
    let hitsThirtyDays = exists
      ? this.state.watches.domainMonitoring.hitsThirtyDays
      : 0;
    let hitsSevenDays = exists
      ? this.state.watches.domainMonitoring.hitsSevenDays
      : 0;
    let lastDiscovered = exists
      ? this.state.watches.domainMonitoring.lastDiscovered
      : null;

    return {
      description: "Domain Monitors",
      watchPath: hasRoleWatch ? "/monitoring/watches/domainmonitors" : null,
      hitsPath: hasRoleWatch ? "/monitoring/watches/domainmonitorhits" : null,
      hitsTotal,
      hitsThirtyDays,
      hitsSevenDays,
      lastDiscovered,
    };
  };

  getSocialMediaWatchItem = (hasRoleWatch) => {
    let exists = this.state.watches.socialMediaWatch != null;
    let hitsTotal = exists ? this.state.watches.socialMediaWatch.hitsTotal : 0;
    let hitsThirtyDays = exists
      ? this.state.watches.socialMediaWatch.hitsThirtyDays
      : 0;
    let hitsSevenDays = exists
      ? this.state.watches.socialMediaWatch.hitsSevenDays
      : 0;
    let lastDiscovered = exists
      ? this.state.watches.socialMediaWatch.lastDiscovered
      : null;

    return {
      description: "Social Media Watches",
      watchPath: hasRoleWatch ? "/monitoring/watches/socialmediawatches" : null,
      hitsPath: hasRoleWatch
        ? "/monitoring/watches/socialmediawatchhits"
        : null,
      hitsTotal,
      hitsThirtyDays,
      hitsSevenDays,
      lastDiscovered,
    };
  };

  getTable = () => {
    return (
      <table>
        <thead>
          <tr>
            <th>Type</th>
            <th>Hits Total</th>
            <th>Hits 30 Days</th>
            <th>Hits 7 Days</th>
            <th>Last Discovered</th>
          </tr>
        </thead>
        <tbody>{this.getItems().map((o) => this.getRow(o))}</tbody>
      </table>
    );
  };

  getRow = (item) => {
    return (
      <tr key={"row-" + item.description}>
        <td>{item.description}</td>
        <td>{FormatIntegerMonospace(item.hitsTotal)}</td>
        <td>{FormatIntegerMonospace(item.hitsThirtyDays)}</td>
        <td>{FormatIntegerMonospace(item.hitsSevenDays)}</td>
        <td>{FormatDateMonospace(item.lastDiscovered)}</td>
        <td>{this.getButtons(item.watchPath, item.hitsPath)}</td>
      </tr>
    );
  };

  getButtons = (watchPath, hitsPath) => {
    let watchesButton = (
      <TableButton
        text="Watches"
        className="secondary"
        prefix="far"
        icon="file-alt"
      />
    );
    if (watchPath) {
      watchesButton = (
        <LinkContainer key="watches-button-key" to={watchPath}>
          {watchesButton}
        </LinkContainer>
      );
    }

    let hitsButton = (
      <TableButton
        text="Hits"
        className="secondary"
        prefix="fas"
        icon="bullseye"
      />
    );
    if (hitsPath) {
      hitsButton = (
        <LinkContainer key="watches-button-key" to={hitsPath}>
          {hitsButton}
        </LinkContainer>
      );
    }
    return (
      <div>
        {watchesButton}
        {hitsButton}
      </div>
    );
  };

  getFooter = () => {
    if (this.state.loading) {
      return (
        <div key="loading" className="footer-text">
          Loading, please wait...
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <DivStyle style={this.props.style}>
        {this.getTable()}
        {this.getFooter()}
      </DivStyle>
    );
  }
}
