import React, { Component } from "react";
import styled from "styled-components";
import Colors from "../common/theme/Colors";
import { FormatDateTime } from "../common/Utils";

const WatchComments = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
`;

const WatchCommentRows = styled.div`
  display: flex;
  flex-direction: column;
`;

const WatchCommentHeader = styled.div`
    font-style: italic; 
`;
const WatchComment = styled.div`
  border-radius: 0.8rem;
  max-width: 75%;
  padding: 0.2rem 0.875rem;
  position: relative;
  text-wrap: initial;
  width: fit-content;
  margin: 0.2rem 0.8rem;
  white-space: pre-line;
  font-size: small;

  &.left {
    align-items: flex-start;
    background-color: ${Colors.messageLeftBackground};
    color: ${Colors.messageLeftText};
    overflow-wrap: anywhere;
  }

  &.right {
    align-self: flex-end;
    background-color: ${Colors.messageRightBackground};
    color: ${Colors.messageRightText};
    overflow-wrap: anywhere;
  }
`;

export class WatchCommentListComponent extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({ comments: this.getComments() });
  }

  getComments = () => {
    let comments = [];
    if (this.props.comments && this.props.comments.length > 0) {
      this.props.comments.forEach((o) => {
        comments.push(this.getComment(o, "left"));
      });
    }
    return (
      <WatchComments
        style={{
          height: `${this.props.height}`,
          overflow: `${this.props.overflow}`,
        }}
      >
        <WatchCommentRows>{comments}</WatchCommentRows>
      </WatchComments>
    );
  };

  getComment = (comment, className) => {
    return (
      <WatchComment className={className}>
        <WatchCommentHeader>
            {FormatDateTime (comment.date)} {comment.author}
          <br></br>
          <br></br>
        </WatchCommentHeader>
        {comment.text}
      </WatchComment>
    );
  };

  render() {
    return <>{this.getComments()}</>;
  }
}
