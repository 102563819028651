import styled from "styled-components";
import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownButton } from "../common/Buttons";
import { StateItems } from "./StateUtil";

const StateFilterStyle = styled.span`
  .dropdown {
    vertical-align: top;
    margin-left: 0px !important;
    margin-right: 20px;
  }
`;

export default class StateFilter extends Component {
  render() {
    let items = [];
    let title = "";

    let itemsToAdd = StateItems.slice();
    itemsToAdd.unshift({
      item: "",
      name: "Any State",
      description: "Any State",
    });

    itemsToAdd.forEach((item) => {
      items.push(
        <Dropdown.Item
          key={item.item}
          onClick={() => this.props.onChange(item)}
        >
            {item.name}
        </Dropdown.Item>,
      );

      if (item.item === this.props.value.item) {
        title = item.name;
      }
    });

    return (
      <StateFilterStyle>
        <DropdownButton title={title} style={{ verticalAlign: "top" }}>
          {items}
        </DropdownButton>
      </StateFilterStyle>
    );
  }
}
